import React from 'react'
import Section from 'src/components/Section'
import Hero from 'src/components/Hero'
import GradientOverlay from 'src/components/GradientOverlay'
import FlexibleLogo from 'src/components/FlexibleLogo'
import HeroContent from 'src/components/HeroContent'
import Typography from 'src/components/Typography'
import styled from 'styled-components'
import Layout from '../layout'


const StyledSection = styled(Section)`
  margin: 0;
`

const NotFoundPage = () => (
  <Layout>
    <StyledSection border={false} flush>
      <Hero>
        <GradientOverlay color="bottomLeft" />
        <FlexibleLogo color="primary" size={80} />
        <HeroContent>
          <Typography variant="heroLabel" paragraph>
            Fehler 404
          </Typography>
          <Typography variant="hero" paragraph>
            Die von Ihnen gewünschte Seite konnte nicht gefunden werden.
          </Typography>
        </HeroContent>
      </Hero>
    </StyledSection>
  </Layout>
)

export default NotFoundPage
